import React from "react";
import "./Hero.css";

export const Hero = () => {
  return (
    <section className="container-hero">
      <h1 className="titulo-hero">Revista Sur Activo</h1>
    </section>
  );
};
