import React from "react";
import "./Carousel.css";
import OwlCarousel from "react-owl-carousel";
import mountain_bike_c from "../../../../Multimedia/mountainbike.webp";
import rafting_c from "../../../../Multimedia/rafting.webp";
import trekking_c from "../../../../Multimedia/trekking.webp";
import cabalgatas_c from "../../../../Multimedia/img_carrusel/cabalgatas.webp";
import tenis_c from "../../../../Multimedia/tenis.webp";
import ski_c from "../../../../Multimedia/ski.webp";
//DATOS OWL
const datosOwl = [
  { titulo: "Trekking", img: trekking_c ,direccion:'https://ojosviajeros.cl/?gad_source=1&gclid=CjwKCAjw5qC2BhB8EiwAvqa41rLCiN330AsjW7DtC_6nRqnv7evrmzGJwuNeaFnn1D89C6BTf716txoCqcsQAvD_BwE'},
  { titulo: "Rafting", img: rafting_c ,direccion:'https://www.trancura.cl/'},
  { titulo: "Cabalgatas", img: cabalgatas_c ,direccion:'https://cabalgataschile.cl/sur-chiloe/'},
  { titulo: "Tenis", img: tenis_c ,direccion:'https://www.easycancha.com/canchas/tenis/chile'},
  { titulo: "Mountain Bike", img: mountain_bike_c ,direccion:'https://es.wikiloc.com/rutas/mountain-bike/chile/los-lagos'},
  { titulo: "Ski", img: ski_c,direccion:'https://www.chileanski.com/esp/pucon/' },
];
// OWL CONFIG
const options = {
  loop: true,
  margin: 10,
  nav: true,
  responsive: {
    0: {
      items: 1,
    },
    767: {
      items: 3,
    },
    1000: {
      items: 5,
    },
  },
};

const Carousel = () => (
  <div className="container-owl">
    <OwlCarousel className="owl-theme" {...options}>
      {datosOwl.map((array,index) => (
        <a className="link-owl-responsive" key={index} href={array.direccion}>
          <h1>{array.titulo}</h1>
          <img src={array.img} />
        </a>
      ))}
    </OwlCarousel>
  </div>
);

export default Carousel;
