import React from "react";
import "./Tarjeta.css";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import { useEffect } from "react";

export const Tarjeta = ({ datosTarjeta,abrirVideo }) => {
   
  return (
    <Card
      style={{
        width: "18rem",
        backgroundColor: datosTarjeta.datosProceso.fondo_proceso,
      }}
    >
      <Card.Img variant="top" src={datosTarjeta.img} />
      <Card.Body>
        <Card.Title style={{color:datosTarjeta.color_titulo}}>{datosTarjeta.titulo}</Card.Title>
        {/* <Card.Text>
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </Card.Text> */}
      </Card.Body>
      <ListGroup className="list-group-flush">
        <ol>
          <li>
            <ListGroup.Item
              style={{
                backgroundColor: datosTarjeta.datosProceso.fondo_proceso,
              }}
            >
              <h6
                style={{ color: datosTarjeta.datosProceso.color_texto_proceso }}
              >
                {datosTarjeta.datosProceso.proceso_1.texto_proceso}
              </h6>
              <img src={datosTarjeta.datosProceso.proceso_1.img} alt="" />
            </ListGroup.Item>
          </li>
          <li>
            <ListGroup.Item
              style={{
                backgroundColor: datosTarjeta.datosProceso.fondo_proceso,
              }}
            >
              <h6
                style={{ color: datosTarjeta.datosProceso.color_texto_proceso }}
              >
                {datosTarjeta.datosProceso.proceso_2.texto_proceso}
              </h6>{" "}
              <img src={datosTarjeta.datosProceso.proceso_2.img} alt="" />
            </ListGroup.Item>
          </li>
          <li>
            <ListGroup.Item
              style={{
                backgroundColor: datosTarjeta.datosProceso.fondo_proceso,
              }}
            >
              <h6
                style={{ color: datosTarjeta.datosProceso.color_texto_proceso }}
              >
                {datosTarjeta.datosProceso.proceso_3.texto_proceso}
              </h6>{" "}
              <img src={datosTarjeta.datosProceso.proceso_3.img} alt="" />
            </ListGroup.Item>
          </li>
          <li>
            <ListGroup.Item
              style={{
                backgroundColor: datosTarjeta.datosProceso.fondo_proceso,
              }}
            >
              <h6
                style={{ color: datosTarjeta.datosProceso.color_texto_proceso }}
              >
                {datosTarjeta.datosProceso.proceso_4.texto_proceso}
              </h6>{" "}
              <img src={datosTarjeta.datosProceso.proceso_4.img} alt="" />
            </ListGroup.Item>
          </li>
        </ol>
      </ListGroup>
      <Card.Body className="container-btn-ski">
        <button className="btn-ski" style={{display:datosTarjeta.btn_video}} onClick={abrirVideo}>Video</button>
      </Card.Body>
    </Card>
  );
};
