import React, { useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import menu from "../../Multimedia/icons8-menu.svg";
import { Link } from "react-router-dom";

import "./Menuchico.css";

function Menuchico({ abrirFormulario}) {

  return (
    <Dropdown>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        <img src={menu} />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item>
          <Link className="texto-menu" to="/">
            Inicio
          </Link>
        </Dropdown.Item>
        <hr className="hr-menuchico"></hr>
        <Dropdown.Item>
          <Link
            className="texto-menu"
            to="/servicios" /*onClick={handleClick}*/
          >
            Nuestros Servicios
          </Link>
        </Dropdown.Item>
        <hr className="hr-menuchico"></hr>
        <Dropdown.Item href="#/action-3">
          <a className="btn-contactanos texto-menu" onClick={abrirFormulario}>
            Contáctanos
          </a>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default Menuchico;
