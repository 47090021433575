import React from "react";
import "./ArticuloReutilizable.css";
import reportero from "../../Multimedia/reportero.jpeg";
import { Tarjeta } from "../Reactstrap components/Tarjeta";

export const ArticuloReutilizable = ({ objeto,abrirVideo }) => {
  return (
    <article
      className="articulo"
      style={{ backgroundColor: objeto.colorFondo }}
    >
      <div className={objeto.clase}>
        <div className="grid-item-articulo titulo" style={{flexDirection:objeto.flex}}>
          <img src={objeto.emoji} style={{height:objeto.tamaño_emoji}}/>
          <h1 style={{ color: objeto.color_titulo, fontSize:objeto.size_titulo }}>{objeto.titulo}</h1>
        </div>
        <div className="grid-item-articulo foto ">
          <img
            className="reportero"
            src={objeto.img}
            style={{ border: objeto.borde_img }}
          />
        </div>
        <div className="grid-item-articulo grid-container-proceso footer-grid">
          <div>
            <div
              className="etapa-reportaje"
              style={{
                backgroundColor: objeto.datosProceso.fondo_proceso,
                border: objeto.datosProceso.borde_proceso,
              }}
            >
              <img src={objeto.datosProceso.proceso_1.img} />
              <h6 style={{ color: objeto.datosProceso.color_texto_proceso }}>
                {objeto.datosProceso.proceso_1.texto_proceso}
              </h6>
            </div>
          </div>
          <img className="flecha" src={objeto.flechas} />
          <div>
            <div
              className="etapa-reportaje"
              style={{
                backgroundColor: objeto.datosProceso.fondo_proceso,
                border: objeto.datosProceso.borde_proceso,
              }}
            >
              <img src={objeto.datosProceso.proceso_2.img} />
              <h6 style={{ color: objeto.datosProceso.color_texto_proceso }}>
                {objeto.datosProceso.proceso_2.texto_proceso}
              </h6>
            </div>
          </div>
          <img className="flecha" src={objeto.flechas} />
          <div>
            <div
              className="etapa-reportaje"
              style={{
                backgroundColor: objeto.datosProceso.fondo_proceso,
                border: objeto.datosProceso.borde_proceso,
              }}
            >
              <img src={objeto.datosProceso.proceso_3.img} />
              <h6 style={{ color: objeto.datosProceso.color_texto_proceso }}>
                {objeto.datosProceso.proceso_3.texto_proceso}
              </h6>
            </div>
          </div>
          <img className="flecha" src={objeto.flechas} />
          <div>
            <div
              className="etapa-reportaje"
              style={{
                backgroundColor: objeto.datosProceso.fondo_proceso,
                border: objeto.datosProceso.borde_proceso,
              }}
            >
              <img src={objeto.datosProceso.proceso_4.img} />
              <h6 style={{ color: objeto.datosProceso.color_texto_proceso }}>
                {objeto.datosProceso.proceso_4.texto_proceso}
              </h6>
            </div>
          </div>
        </div>
      </div>
      <Tarjeta datosTarjeta={objeto} abrirVideo={abrirVideo}/>
    </article>
  );
};
