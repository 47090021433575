import React from 'react'
import { Hero } from '../../components/components inicio/Hero'
import { Sur } from '../../components/components inicio/Sur'
import { Descubre } from '../../components/components inicio/Descubre';
import Carousel from '../../components/components inicio/Montajes/Carousel Owl/Carousel';


export const Inicio = () => {
  return (
    <>
    <Hero/>
    <Carousel />
    <Sur/>
    <Descubre/>
    
    
    </>
  )
}
