import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "./App.css";
import Logo from "../src/Multimedia/LogoRevista.png";
import { Footer } from "./components/footer/Footer";
import { Inicio } from "./pages/inicio/Inicio";
import { Servicios } from "./pages/nuestros servicios/Servicios";
import { useState } from "react";
import Modal from "react-modal";
import Formulario from "./components/components form/Formulario";
import Menuchico from "./components/Reactstrap components/Menuchico.js";
import "owl.carousel/dist/assets/owl.carousel.css";

function App() {
  //  Estados
  const [deploy, setDeploy] = useState(false);
  const [lista, setLista] = useState(false);
  const [form, setForm] = useState(false);
  const [fondo,setFondo]=useState('orange')

  //Manipulacion de estados
  const desplegar = () => {
    setDeploy(true);
  };
  const quitarDespliegue = () => {
    setDeploy(false);
  };
  const abrirLista = () => {
    setLista(true);
  };
  const cerrarLista = () => {
    setLista(false);
  };
  const abrirFormulario = () => {
    setForm(true);
  };
  const cerrarFormulario = () => {
    setForm(false);
  };

  //Funcion para animacion de header
  window.addEventListener("scroll", function () {
    var cabecera = document.querySelector("header");
    cabecera.classList.toggle("sticky", window.scrollY > 0);
  });
  const handleClick = () => {
    window.scrollTo(0, 0); // Esto lleva la página a la parte superior
};

//Cambiar color del body segun la página
let cuerpo=document.querySelector('body');
cuerpo.style.backgroundColor=`${fondo}`


  return (
    <Router className="APP">
      <header>
        <div className="container-logo">
          <Link to="/" onClick={handleClick}>
            <img className="Logo" src={Logo} />
          </Link>
        </div>
        <Menuchico abrirFormulario={abrirFormulario} />
        <nav className="container-menu">
          <ul>
            <li>
              <Link to="/" onClick={()=>setFondo('orange')}>Inicio</Link>
            </li>
            <li /*onMouseEnter={abrirLista} onMouseLeave={cerrarLista}*/>
              <Link to="/servicios" onClick={()=>{handleClick();setFondo('#ADD8E6')}}>
                Nuestros Servicios
              </Link>
              {/* {lista == true && (
                <ul id="lista-desplegada">
                  <li className="sublista">
                    <Link to="/servicios#reportajes">Reportajes</Link>
                  </li>
                  <li className="sublista">
                    <Link to="/servicios#guia">Guia Turística</Link>
                  </li>
                  <li className="sublista">
                    <Link to="/servicios#ski">Clases de Ski particulares</Link>
                  </li>
                  <li className="sublista">
                    <Link to="/servicios#directorios">
                      Directorio de negocios
                    </Link>
                  </li>
                </ul>
              )} */}
            </li>
            <li>
              <a className="btn-contactanos" onClick={abrirFormulario}>
                Contáctanos
              </a>
            </li>
            <li>
              <span onMouseEnter={desplegar} onMouseLeave={quitarDespliegue}>
                Propósito
              </span>
              {deploy == true && (
                <div id="proposito">
                  <p>
                    Hoy en día, todo se ha digitalizado, relegando a las
                    revistas físicas. Las redes sociales han permitido que cada
                    persona cree su propio mundo y opiniones, lo que ha causado
                    una separación ideológica. Con esta revista, queremos unir
                    ideas, eventos y personas, creando un espacio común para
                    todos.
                  </p>
                </div>
              )}
            </li>
            
          </ul>
        </nav>
      </header>
      <main>
        <Routes>
          <Route path="/" element={<Inicio />}></Route>
          <Route path="/servicios" element={<Servicios />}></Route>
        </Routes>
      </main>
      <footer>
        <Footer />
      </footer>
      <Modal isOpen={form} style={customStyles}>
        <Formulario cerrarFormulario={cerrarFormulario} />
      </Modal>
    </Router>
  );
}
// estilos formulario
const customStyles = {
  content: {
    top: "55%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#ADD8E6",
  },
};

export default App;
