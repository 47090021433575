import React, { useState } from "react";
import "./Formulario.css";
import { useFormik } from "formik";
import * as Yup from "yup";

const Formulario = ({ cerrarFormulario }) => {
  // Esquema de validación
  const validationSchema = Yup.object().shape({
    nombre: Yup.string()
      .min(3, "El nombre es muy corto")
      .max(30, "Demasiado largo")
      .matches(/^[a-zA-Z ]+$/, "Porfavor,ingresa un formato válido (sin números)")
      .required("Requerido"),
    apellido: Yup.string()
      .min(3, "Apellido muy corto")
      .max(30, "Demasiado largo"),
    email: Yup.string().required(
      "Requerido (Necesitamos tu correo para poder comunicarnos)"
    ),
  });

  // Formik
  const formik = useFormik({
    initialValues: {
      nombre: "",
      apellido: "",
      email: "",
      comentario: "",
    },
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
      document.querySelectorAll('.form-none').forEach(
        elemento =>{elemento.style.display='none'}
      );
      setUsuario(formik.values.nombre);
      document.querySelector('.form-exitoso').style.display='block'
    }
  });

  //estado (nombre) de usuario registrado
  const [usuario,setUsuario]=useState('')

  return (
    <div className='container-formulario'>
      <h1 className="form-none">Formulario</h1>
      <h3 className="form-none">
        Si quieres colaborar o simplemente comunicarte con nosotros, por favor
        llena el siguiente formulario
      </h3>
      <form onSubmit={formik.handleSubmit} className="form-none">
        <div className="form-group">
          <label htmlFor="nombre">Tu nombre:</label>
          <input
            type="text"
            id="input-nombre"
            name="nombre"
            value={formik.values.nombre}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.errors.nombre && formik.touched.nombre && (
            <div className="form-error">{formik.errors.nombre}</div>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="apellido">Tu apellido:</label>
          <input
            type="text"
            id="input-apellido"
            name="apellido"
            value={formik.values.apellido}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.errors.apellido && formik.touched.apellido && (
            <div className="form-error">{formik.errors.apellido}</div>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="email">Tu correo:</label>
          <input
            type="email"
            id="input-email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.errors.email && formik.touched.email && (
            <div className="form-error">{formik.errors.email}</div>
          )}
        </div>
        <div className="form-group flex-comentario">
          <label htmlFor="comentario">Comentario:</label>
          <textarea
            id="input-comentario"
            name="comentario"
            value={formik.values.comentario}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          ></textarea>
        </div>
        <input type="submit" className="form-btns" />
        <button type="button" className="form-btns" onClick={cerrarFormulario}>
          Cerrar
        </button>
      </form>
      <div className="form-exitoso">
        <h1>¡Formulario exitoso!</h1>
        <h5><span>{usuario}</span> haz completado y enviado el formulario con éxito </h5>
        <button onClick={cerrarFormulario}>Cerrar</button>
      </div>
    </div>
  );
};

export default Formulario;
