import React, { useEffect, useState } from "react";
import "./Descubre.css";
import mountain_bike from "../../Multimedia/mountainbike.webp";
import ski from "../../Multimedia/ski.webp";
import tinajas from "../../Multimedia/tinaja.webp";
import fogata from "../../Multimedia/Fogata.webp";
import cabalgatas from "../../Multimedia/cabalgatas.webp";
import trekking from "../../Multimedia/trekking.webp";
import paracaidismo from "../../Multimedia/paracaidismo.webp";
import restaurantes from "../../Multimedia/restaurantes.webp";
import padel from "../../Multimedia/padel.webp";
import rafting from "../../Multimedia/rafting.webp";
import termas from "../../Multimedia/Termas.webp";
import tenis from "../../Multimedia/tenis.webp";
import cabañas from "../../Multimedia/cabañas.png";
import ski_icono from "../../Multimedia/esqui.png";
import { CarruselDescubre } from "./Montajes/CarruselDescubre";

export const Descubre = () => {
  //estado para visibilidad del carrusel
  const [visible, setVisible] = useState(false);

  //estado id para enviar a carrusel
  const [identificador, setIdentificador] = useState("");
  //Datos para el contenedor grid
  const datosGrid = [
    { id: 1, img: mountain_bike, texto: " Mountain Bike" },
    { id: 2, img: rafting, texto: "Rafting" },
    { id: 3, img: fogata, texto: "Fogatas" },
    { id: 4, img: termas, texto: "Termas" },
    { id: 5, img: trekking, texto: "Trekking" },
    { id: 6, img: cabalgatas, texto: "Cabalgatas" },
    { id: 7, img: restaurantes, texto: "Restaurantes" },
    { id: 8, img: padel, texto: "Padel" },
    { id: 9, img: paracaidismo, texto: "Paracaidismo" },
    { id: 10, img: tinajas, texto: "Tinajas" },
    { id: 11, img: tenis, texto: "Tenis" },
    { id: 12, img: ski, texto: "Ski" },
  ];

  //API intersection observer
  useEffect(() => {
    // función
    function animacion(entries) {
      entries.forEach((item) => {
        if (item.isIntersecting) {
          item.target.classList.add("active");
        }
      });
    }

    // creamos el observador
    const observador = new IntersectionObserver(animacion, {
      threshold: [0.1],
    });

    // seleccionamos el elemento (NodeList)
    const gridItems = document.querySelectorAll(".grid-item");

    // observamos el objeto
    gridItems.forEach((item) => observador.observe(item));

    return () => {
      gridItems.forEach((item) => observador.unobserve(item));
    };
  }, []);

  //Activación de carrusel
  useEffect(() => {
    //funcion
    function activarCarrusel() {
      setVisible(true);
    }

    //elementos dom ,junto a su escuchador
    document.querySelectorAll(".grid-item").forEach((item) => {
      item.addEventListener("click", activarCarrusel);
    });

    //limpieza del evento
    return () => {
      document.querySelectorAll(".grid-item").forEach((item) => {
        item.removeEventListener("click", activarCarrusel);
      });
    };
  }, [identificador]);
  function desactivarCarrusel(dato) {
    setVisible(dato);
  }

  const enviarDatos = (id) => {
    setIdentificador(id);
  };

  return (
    <>
      <section className="container-descubre">
        <div className="container-titulos">
          <img src={cabañas} className="cabañas" />
          <img src={ski_icono} className="ski_icono" />
          <h1>Descubre!</h1>
          <h2>Actividades para realizar en el sur de Chile</h2>
          <span className="h1-responsive">Actividades en el Sur</span>
        </div>
        <section className="container-grid-container">
          <article className="grid-container">
            {datosGrid.map((array) => (
              <div
                className="grid-item-navlink"
                onClick={() => {
                  enviarDatos(array.id);
                }}
                key={array.id}
              >
                <figure className="grid-item">
                  <img
                    className="foto-figure"
                    src={array.img}
                    alt="foto-figure"
                  />
                  <figcaption className="figcaption">{array.texto}</figcaption>
                </figure>
              </div>
            ))}
          </article>
        </section>
      </section>
      {visible == true && (
        <CarruselDescubre
          fxDesactivadora={desactivarCarrusel}
          id={identificador}
        />
      )}
    </>
  );
};
