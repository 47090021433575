import React, { useState } from "react";
import "./Servicios.css";
import reportero from "../../Multimedia/reportero.jpeg";
import mapa from "../../Multimedia/mapa.jpeg";
import directorio from "../../Multimedia/directorio.jpeg";
import ski from "../../Multimedia/ski.jpeg";
import video_nieve from "../../Multimedia/video_nieve.mp4";
import flecha from "../../Multimedia/angulo-de-la-flecha-apuntando-hacia-la-derecha.png";
import celular from "../../Multimedia/telefono-inteligente.png";
import camino from "../../Multimedia/la-carretera.png";
import mano from "../../Multimedia/escritura.png";
import revista from "../../Multimedia/revista.png";
import particulares from "../../Multimedia/team-building.png";
import traslado from "../../Multimedia/van-de-carga.png";
import equipacion from "../../Multimedia/equipo_ski.png";
import ticket from "../../Multimedia/pasaje-de-ski.png";
import mapa_info from "../../Multimedia/mapa.png";
import microfono from "../../Multimedia/karaoke.png";
import escudo from "../../Multimedia/escudo-seguro.png";
import itinerario from "../../Multimedia/itinerario.png";
import apreton from "../../Multimedia/apreton-de-manos.png";
import recopilacion from "../../Multimedia/recopilacion-de-datos.png";
import diseño from "../../Multimedia/diseno-grafico.png";
import publicacion from "../../Multimedia/publicacion-de-instagram.png";
import video_ski from "../../Multimedia/video_nieve.mp4";
import carta from "../../Multimedia/iconos/tabla.png";
import skimoji from "../../Multimedia/iconos/skimoji.png";
import directoriomoji from "../../Multimedia/iconos/directoriomoji.png";
import mapamoji from "../../Multimedia/iconos/mapa.png";

import Modal from "react-modal";
import { ArticuloReutilizable } from "../../components/components nuestros servicios/ArticuloReutilizable";

export const Servicios = () => {
  const [video, setVideo] = useState(false);
  const abrirVideo = () => {
    setVideo(true);
  };
  const cerrarVideo = () => {
    setVideo(false);
  };
  const objetoReportaje = {
    clase: "grid-container-articulo",
    img: reportero,
    emoji: carta,
    tamaño_emoji: "50%",
    titulo: "Reportajes",
    color_titulo: "#FFFFFF",
    colorFondo: "lightblue",
    flechas: flecha,
    datosProceso: {
      fondo_proceso: "#1C516B",
      color_texto_proceso: "#FFFFFF",
      proceso_1: { img: celular, texto_proceso: "Contactanos" },
      proceso_2: { img: camino, texto_proceso: "Visitamos la zona" },
      proceso_3: { img: mano, texto_proceso: "Documentamos el suceso" },
      proceso_4: {
        img: revista,
        texto_proceso: "Lo publicamos en Revista sur Activo",
      },
    },
  };
  const objetoGuia = {
    clase: "grid-container-articulo-invertido",
    img: mapa,
    emoji: mapamoji,
    flex: "column",
    tamaño_emoji: "30%",

    borde_img: " solid rgb(225, 131, 30) 20px",
    titulo: "Guía Turística",
    color_titulo: "#FFFFFF",
    size_titulo: "5rem",
    colorFondo: "#508330",
    datosProceso: {
      fondo_proceso: "#E8C756",
      color_texto_proceso: "#713E18",
      proceso_1: { img: mapa_info, texto_proceso: "Información de lugares" },
      proceso_2: {
        img: escudo,
        texto_proceso: "Seguridad y bienestar de los turistas",
      },
      proceso_3: {
        img: microfono,
        texto_proceso: "Actividades y juegos interactivos",
      },
      proceso_4: {
        img: itinerario,
        texto_proceso: "Itinerario basado en su tiempo",
      },
    },
  };
  const objetoSki = {
    clase: "grid-container-articulo",
    img: ski,
    emoji: skimoji,
    tamaño_emoji: "50%",
    titulo: "Clases de Ski",
    color_titulo: "#FFFFFF",
    colorFondo: "#089FDB",
    btn_video: "block",
    datosProceso: {
      fondo_proceso: "lightblue",
      color_texto_proceso: "#1B1E8D",
      proceso_1: {
        img: particulares,
        texto_proceso: "Clases particulares de dos horas",
      },
      proceso_2: { img: traslado, texto_proceso: "Traslados" },
      proceso_3: { img: equipacion, texto_proceso: "Equipo de Ski" },
      proceso_4: { img: ticket, texto_proceso: "Ticket telesillas" },
    },
  };
  const objetoDirectorio = {
    clase: "grid-container-articulo-invertido",
    img: directorio,
    emoji: directoriomoji,
    flex: "column",
    tamaño_emoji: "30%",
    borde_img: " solid #333333 20px",
    titulo: "Directorio de Negocios locales",
    color_titulo: "#333333",
    colorFondo: "#32CD32",
    datosProceso: {
      borde_proceso: "solid 3px #555555",
      color_texto_proceso: "#333333",
      proceso_1: { img: apreton, texto_proceso: "Contacto y Consulta inicial" },
      proceso_2: {
        img: recopilacion,
        texto_proceso: "Recopilación y Envío de materiales",
      },
      proceso_3: { img: diseño, texto_proceso: "Diseño y Preparación" },
      proceso_4: {
        img: publicacion,
        texto_proceso: "Publicación y Seguimiento",
      },
    },
  };
  return (
    <div className="container-servicios">
      <ArticuloReutilizable objeto={objetoReportaje} />
      <ArticuloReutilizable objeto={objetoGuia} />
      <ArticuloReutilizable objeto={objetoSki} abrirVideo={abrirVideo} />
      <ArticuloReutilizable objeto={objetoDirectorio} />
      <Modal isOpen={video} style={customStyles}>
        <h1 className="titulo-video">
          ¿Quieres dar tus primeros pasos en Ski?
        </h1>
        <video controls className="video-ski">
          <source src={video_ski} type="video/mp4" />
        </video>
        <div className="container-btn-video">
          <button>
            <a href="https://wa.me/+56940551892" target="_blank">
              Nuestro Whatsapp
            </a>
          </button>
          <button onClick={cerrarVideo}>Cerrar</button>
        </div>
        <h2 className="unete">¡Unete!</h2>
      </Modal>
    </div>
  );
};

// estilos formulario
const customStyles = {
  content: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "lightblue",
    alignItems: "center",
  },
};
