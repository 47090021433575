import React, { useEffect } from "react";
import "./Sur.css";
import sur_img_1 from "../../Multimedia/volcan.jpg";
import termas from "../../Multimedia/termas_sur (1).webp";
import rustico from "../../Multimedia/rustico.webp";
import termas_ico from "../../Multimedia/termas.png";

export const Sur = () => {
  useEffect(() => {
    // Función para grid items
    function animacion(entrante) {
      let objeto = entrante[0];
      if (objeto.isIntersecting) {
        surdechile.classList.add("activa");
        parrafo.classList.add("activa");
      } else {
        surdechile.classList.remove("activa");
        parrafo.classList.remove("activa");
      }
    }
    // Creamos observador para textos sur
    let observador = new IntersectionObserver(animacion, {});
    // Seleccionamos elementos del DOM
    let surdechile = document.getElementById("sur-de-chile");
    let parrafo = document.querySelector(".parrafo-surdechile");
    // Observamos a surdechile
    if (surdechile) {
      observador.observe(surdechile);
    }

    // Limpieza del observador al desmontar el componente
    return () => {
      if (surdechile) {
        observador.unobserve(surdechile);
      }
    };
  }, []);

  return (
    <section className="container-actividades">
      <hr className="separador"></hr>
      <section className="container-articulos">
        <article className="articulos">
          <h5>Intro</h5>
          <h1 id="sur-de-chile">Sur de Chile</h1>
          <div className="container-img-parrafo">
            <p className="parrafo-surdechile">
              El sur de Chile te ofrece paisajes únicos,variados y encantadores!{" "}
            </p>
            <a href="https://centrovolcanosorno.cl/" target="blank"><img className="img-volcan" src={sur_img_1} /></a>
            
          </div>
        </article>
      </section>
      <hr className="separador"></hr>
      <section className="container-articulos">
        <article className="articulos devolver-termas">
          <h1 id="termas">Termas</h1>
          <div className="container-img-parrafo">
            <p className="parrafo-termas">
              Un lugar de reflexión y conexión con la naturaleza,perfecto para
              calmar nuestra cuerpo y nuestra mente...
            </p>
            <a href='https://www.termasgeometricas.cl/' target="blank">
            <img className="img-termas" src={termas} />
            </a>
          </div>
        </article>
      </section>
      <hr className="separador devolver-termas"></hr>
      <section className="container-articulos">
        <article className="articulos">
          <h1 id="estilo">Estilo</h1>
          <div className="container-img-parrafo">
            <p className="parrafo-estilo">
              El sur de Chile cuenta con un estilo unico,la mezcla entre rustico
              y calido ,el perfecto concepto de "Hygge".
            </p>
            <img className="img-volcan" src={rustico} />
          </div>
        </article>
      </section>
    </section>
  );
};
