import React from "react";
import "./Footer.css";
import logo_react from "../../Multimedia/reactlogo.png";
import logo_instagram from "../../Multimedia/instagram-svgrepo-com.svg";
import logo_facebook from "../../Multimedia/Facebook F Icon.svg";

export const Footer = () => {
  return (
    <section className="container-footer">
      <h5>¡Encuentranos en Redes Sociales!</h5>
      <hr className="hr-footer"></hr>
      <div className="container-redes-sociales">
        <h6 className="creada-con">
          Desarrollada con <a href="https://es.react.dev/"  target="_blank"><img src={logo_react}/></a>
        </h6>
        <div className="container-iconos">
          <a href="https://www.instagram.com/revista_sur_activo?igsh=MTE3MTltMDM1OW9obg=="  target="_blank">
            <img src={logo_instagram} alt="" />
          </a>
          <a href="https://web.facebook.com/unnuevopuntodeencuentro"  target="_blank">
            <img src={logo_facebook} alt="" />
          </a>
        </div>
      </div>
    </section>
  );
};
