import React, { useState } from "react";
import "./CarruselDescubre.css";
import { Carousel } from "react-bootstrap";
import mountain_bike_c from "../../../Multimedia/img_carrusel/mountainbike.webp";
import rafting_c from "../../../Multimedia/img_carrusel/rafting.webp";
import fogata_c from "../../../Multimedia/img_carrusel/Fogata.webp";
import termas_c from "../../../Multimedia/img_carrusel/Termas.webp";
import trekking_c from "../../../Multimedia/img_carrusel/trekking.webp";
import cabalgatas_c from "../../../Multimedia/img_carrusel/cabalgatas.webp";
import restaurantes_c from "../../../Multimedia/img_carrusel/restaurantes.webp";
import padel_c from "../../../Multimedia/img_carrusel/padel.webp";
import paracaidismo_c from "../../../Multimedia/img_carrusel/paracaidismo.webp";
import tinajas_c from "../../../Multimedia/img_carrusel/tinaja.webp";
import tenis_c from "../../../Multimedia/img_carrusel/tenis.webp";
import ski_c from "../../../Multimedia/img_carrusel/ski.webp";
export const CarruselDescubre = ({ fxDesactivadora, id }) => {
  //Datos Carousel
  const datosCarousel = [
    {
      id: 1,
      img: mountain_bike_c,
      texto: "Mountain Bike",
      link: "https://es.wikiloc.com/rutas/mountain-bike/chile/los-lagos",
    },
    {
      id: 2,
      img: rafting_c,
      texto: "Rafting",
      link: "https://www.trancura.cl/",
    },
    { id: 3, img: fogata_c, texto: "Fogatas", link: "" },
    {
      id: 4,
      img: termas_c,
      texto: "Termas",
      link: "https://www.termasgeometricas.cl/",
    },
    { id: 5, img: trekking_c, texto: "Trekking", link: "" },
    {
      id: 6,
      img: cabalgatas_c,
      texto: "Cabalgatas",
      link: "https://cabalgataschile.cl/sur-chiloe/",
    },
    {
      id: 7,
      img: restaurantes_c,
      texto: "Restaurantes",
      link: "https://www.tripadvisor.cl/Restaurants-g3676471-South_Region.html",
    },
    {
      id: 8,
      img: padel_c,
      texto: "Padel",
      link: "https://www.easycancha.com/es-CL/chile/arriendo/deportes/padel/region-de-los-lagos/puerto-varas/padel-linea-nueva",
    },
    {
      id: 9,
      img: paracaidismo_c,
      texto: "Paracaidismo",
      link: "https://www.instagram.com/skydivepucon/",
    },
    {
      id: 10,
      img: tinajas_c,
      texto: "Tinajas",
      link: "https://cabanascontinajas.cl/",
    },
    {
      id: 11,
      img: tenis_c,
      texto: "Tenis",
      link: "https://www.easycancha.com/canchas/tenis/chile",
    },
    {
      id: 12,
      img: ski_c,
      texto: "Ski",
      link: "https://www.chileanski.com/esp/pucon/",
    },
  ];
  //Estado boleano para componente padre 'descubre.js'
  function enviarFalse() {
    fxDesactivadora(false);
  }
  //estado de datos carrusel
  const [srcActual, setSrcActual] = useState(imgEncontrada);
  const [textoActual, setTextoActual] = useState(textoEncontrado);
  const [linkActual, setLinkActual] = useState(linkEncontrado);
  //Encontrar foto proveniente del click grid-item
  var idItem = id;
  var objetoIdentificado = datosCarousel.find((objeto) => objeto.id === idItem);
  if (objetoIdentificado) {
    var imgEncontrada = objetoIdentificado.img;
    var textoEncontrado = objetoIdentificado.texto;
    var linkEncontrado = objetoIdentificado.link;
  } else {
    console.log("No se encontró la imagen en el Array datosCarousel");
  }
  function cambiarSrc() {
    setSrcActual("dato.img");
    setTextoActual("dato.texto");
    setLinkActual("dato.link");
  }

  return (
    <section className="container-carrusel-descubre">
      <div className="container-relative">
        <span className="btn-close-carrusel" onClick={enviarFalse}>
          x
        </span>
        <Carousel className="carrusel-descubre" onSelect={cambiarSrc} fade>
          {datosCarousel.map((dato) => (
            <Carousel.Item>
              <a
                href={linkActual == "dato.link" ? dato.link : linkEncontrado}
                target="blank"
                className="container-a-caption"
              >
                <img
                  src={srcActual == "dato.img" ? dato.img : imgEncontrada}
                  alt="texto-foto"
                  className="foto-carrusel"
                />
              </a>

              <Carousel.Caption>
                <h3 className="deporte-carrusel">
                  {textoActual == "dato.texto" ? dato.texto : textoEncontrado}
                </h3>
                {/* <p>parrafo deporte</p> */}
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </section>
  );
};
